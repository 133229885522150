import styled from "styled-components";
import React, { useEffect, useState, Suspense } from "react";
//import useWindowDimensions from "../hooks/useWindowDimensions";
import InteractiveGallery from "../components/InteractiveGallery";
const HomeSketch = React.lazy(() => import("../components/HomeSketch"));

const HomePage = () => {
  const [widthState, setWidthState] = useState(800);
  const [heightState, setHeightState] = useState(800);
  const [sketchColor, setSketchColor] = useState([255, 255, 255]);
  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    // let { height, width } = useWindowDimensions();
    const { innerWidth: width, innerHeight: height } = window;
    setHeightState(height);
    setWidthState(width);
  }, []);

  const handleColorBtnClick = () => {
    let red = Math.floor(Math.random() * 255);
    let green = Math.floor(Math.random() * 255);
    let blue = Math.floor(Math.random() * 255);
    setSketchColor([red, green, blue]);
  };

  const handleDropDown = () => {
    if (dropDown == true) {
      setDropDown(false);
    } else {
      setDropDown(true);
    }
    console.log(`DropDown State: `, dropDown);
  };

  return (
    <PageContainer>
      <ElementContainer>
        <div
          style={{
            height: "50px",
            display: "flex",
            justifyContent: "center",
            zIndex: 1000,
          }}
        ></div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <MenuContainer>
            <ColorChangeBtn onClick={handleColorBtnClick}>Bx</ColorChangeBtn>
            <TextIntro>
              Generative MiniMalism <br></br>
              <div style={{ fontSize: "12px" }}>FullStack Engineer</div>
            </TextIntro>
            <TextNav href="#imgdisplay">Portfolio</TextNav>
            {/* <TextNav href="/derriers">Derriers</TextNav> */}
            <br></br>

            <DropDownBtn onClick={handleDropDown}>
              <TextNav>Web3 Projects</TextNav>
            </DropDownBtn>
            <br></br>
            {dropDown ? (
              <>
                <ProjectLinks href="https://opensea.io/collection/the-fugly-vintage-genesis">
                  FuglyAliens
                </ProjectLinks>
                <br></br>
                <ProjectLinks href="https://onedropnfts.com/">
                  OneDropNFTs
                </ProjectLinks>
                <br></br>
                <ProjectLinks href="https://www.magiceden.io/marketplace/primordialmeatballs">
                  Meatballs
                </ProjectLinks>
                <br></br>
                <ProjectLinks href="https://www.magiceden.io/marketplace/pissenlit">
                  Dandelions
                </ProjectLinks>
                <br></br>
              </>
            ) : (
              <div></div>
            )}
          </MenuContainer>
          <div style={{ margin: "0 0 0 0px" }}>
            <Suspense fallback={<div>Loading...</div>}>
              <HomeSketch
                p5height={heightState}
                p5width={widthState}
                p5color={sketchColor}
              />
            </Suspense>
          </div>
        </div>

        {/* <ImageDisplay id="imgdisplay">
        <ImgRow>
          <ImgColumn>
            <img src="/images/dandelion1.png" />
            <img src="/images/dandelion2.png" />
            <img src="/images/dandelion3.png" />
            <img src="/images/dandelion4.png" />
          </ImgColumn>
          <ImgColumn>
            <img src="/images/cateye.png" />
            <img src="/images/circleeye.png" />
            <img src="/images/cateye2.png" />
            <img src="/images/goateye.png" />
          </ImgColumn>
          <ImgColumn>
            <img src="/images/space_pentagram.png" />
            <img src="/images/space_starry.png" />
            <img src="/images/vision_1.png" />
            <img src="/images/spiral.png" />
          </ImgColumn>
          <ImgColumn>
            <img src="/images/derriers1.png" />
            <img src="/images/derriers2.png" />
          </ImgColumn>
        </ImgRow>
      </ImageDisplay> */}
      </ElementContainer>
      <ImageDisplay id="imgdisplay">
        <InteractiveGallery />
      </ImageDisplay>
    </PageContainer>
  );
};

export default HomePage;

const PageContainer = styled.div`
  background-color: #000;
  color: #000;
`;

const ElementContainer = styled.div`
  padding: 0 0;
`;

const MenuContainer = styled.div`
  position: absolute;
  width: 20%;
  //border: 3px solid #ffffff;
  color: #ffffff;
  left: 30px;
  top: 50px;
  height: 1000px;
  background-color: rgba(0, 0, 0, 0.001);
`;

const TextHeader = styled.div`
  font-size: 60px;
  font-family: "Comforter";
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.001);
`;

const TextIntro = styled.div`
  position: relative;
  justify-content: center;
  text-align: left;
  align-items: left;
  margin: 1rem 0 5rem 0;
  padding: 0 0 0 0;
  font-size: 16px;
  font-family: "RockSalt";
  color: #bbbbbb;
  background-color: rgba(0, 0, 0, 0.001);
`;

const TextNav = styled.a`
  margin: 1rem 0 0 0;
  font-size: 20px;
  font-family: "RockSalt";
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.001);

  &:hover {
    color: #aaaaaa;
    cursor: pointer;
  }
`;

// const ImageDisplay = styled.div`
//   margin: 3rem 0rem 0 300px;
//   // display: grid;
//   // grid-template-columns: repeat(4, 200px);
//   // gap: 10px;
//   // background-color: #5589ef;
//   // display: flex;
//   // justify-content: space-around;
// `;

const ImgRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
  align-items: center;
  //background-color: #ffffff;
`;

const ImgColumn = styled.div`
  flex: 50%;
  padding: 10px 10px;
  //background-color: #dddddd;

  img {
    &:hover {
      transform: scale(1.75);
    }
  }
`;

const ColorChangeBtn = styled.button`
  border: #ffffff;
  font-size: 60px;
  font-family: "Comforter";
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.001);

  &:hover {
    color: #ff66ff;
  }
`;

const DropDownBtn = styled.button`
  margin: 1rem 0 0 0;
  font-size: 1rem;
  font-family: "RockSalt";
  color: #ffffff;
  border: none;
  background-color: rgba(0, 0, 0, 0.001);

  &:hover {
    color: #ff66ff;
  }
`;

const ProjectLinks = styled.a`
  margin: 2rem 0 0 0;
  font-size: 14px;
  font-family: "RockSalt";
  color: #dddddd;
  border: none;
  background-color: rgba(0, 0, 0, 0.001);
  padding-left: 40px;
  &:hover {
    color: #8888ff;
  }
`;

const ImageDisplay = styled.div`
  margin-top: 400px;
  background: linear-gradient(to bottom, black, #a3a3a3);
  /* height: 300px; */
  display: flex;
  justify-content: center;
  padding: 200px 0px;
`;
