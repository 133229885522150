import React, { Suspense } from "react";
const SpirographSketch = React.lazy(() =>
  import("../components/SpirographSketch")
);

const SpirographPage = () => {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <SpirographSketch />
      </Suspense>
    </>
  );
};

export default SpirographPage;
