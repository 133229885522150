// InteractiveGallery.jsx
import React, { useState } from "react";
import styled from "styled-components";

const images = [
  "images/cateye.png",
  "images/cateye2.png",
  "images/goateye.png",
  "images/circleeye.png",
  "images/dandy1.png",
  "images/dandy2.png",
  "images/dandelion1.png",
  "images/dandelion2.png",
  "images/derriers1.png",
  "images/derriers2.png",
  "images/space_pentagram.png",
  "images/vision_1.png",
  "images/spiral.png",
];

const InteractiveGallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <>
      {selectedImage && (
        <FullSizeImageContainer>
          <FullSizeImage src={selectedImage} alt="Selected" />
          <CloseButton onClick={() => setSelectedImage(null)}>
            Close
          </CloseButton>
        </FullSizeImageContainer>
      )}

      <GalleryContainer>
        {images.map((image, index) => (
          <Thumbnail
            key={index}
            src={image}
            alt={`Photo ${index + 1}`}
            onClick={() => setSelectedImage(image)}
          />
        ))}
      </GalleryContainer>
    </>
  );
};

export default InteractiveGallery;

const GalleryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  width: 80%;
`;

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.2s ease;
  border-radius: 10px;

  &:hover {
    transform: scale(1.1);
  }
`;

const FullSizeImageContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
`;

const FullSizeImage = styled.img`
  max-width: 80vw;
  max-height: 80vh;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 999;

  color: #fff;
  border-radius: 10px;
  border: 0px;
  &:hover {
    background-color: #303030;
  }
`;
